const area = [
  {
    "value": "松城街道",
    "label": "松城街道",
    "children": [

      {
        "value": "万贤社区",
        "label": "万贤社区"
      },
      {
        "value": "集贤社区",
        "label": "集贤社区"
      },
      {
        "value": "龙贤社区",
        "label": "龙贤社区"
      },
      {
        "value": "兴贤社区",
        "label": "兴贤社区"
      },
      {
        "value": "中乘社区",
        "label": "中乘社区"
      },
      {
        "value": "松兴社区",
        "label": "松兴社区"
      },
      {
        "value": "城北社区",
        "label": "城北社区"
      },
      {
        "value": "俊星社区",
        "label": "俊星社区"
      },
      {
        "value": "俊贤社区",
        "label": "俊贤社区"
      },
      {
        "value": "彩虹社区",
        "label": "彩虹社区"
      },
      {
        "value": "西关社区",
        "label": "西关社区"
      },
      {
        "value": "龙津社区",
        "label": "龙津社区"
      },
      {
        "value": "青福村",
        "label": "青福村"
      },
      {
        "value": "涌山村",
        "label": "涌山村"
      },
      {
        "value": "马洋村",
        "label": "马洋村"
      },
      {
        "value": "七宝洋村",
        "label": "七宝洋村"
      },
      {
        "value": "玉潭村",
        "label": "玉潭村"
      },
      {
        "value": "宝清村",
        "label": "宝清村"
      }
    ]
  },
  {
    "value": "松港街道",
    "label": "松港街道",
    "children": [

      {
        "value": "东关社区",
        "label": "东关社区"
      },
      {
        "value": "东兴社区",
        "label": "东兴社区"
      },
      {
        "value": "东景社区",
        "label": "东景社区"
      },
      {
        "value": "东昇社区",
        "label": "东昇社区"
      },
      {
        "value": "东阳社区",
        "label": "东阳社区"
      },
      {
        "value": "江边村",
        "label": "江边村"
      },
      {
        "value": "利洋村",
        "label": "利洋村"
      },
      {
        "value": "下村村",
        "label": "下村村"
      },
      {
        "value": "八斗贝村",
        "label": "八斗贝村"
      },
      {
        "value": "后港村",
        "label": "后港村"
      },
      {
        "value": "利埕村",
        "label": "利埕村"
      },
      {
        "value": "佳湖村",
        "label": "佳湖村"
      },
      {
        "value": "东关村",
        "label": "东关村"
      },
      {
        "value": "水坑村",
        "label": "水坑村"
      },
      {
        "value": "章家衕村",
        "label": "章家衕村"
      },
      {
        "value": "竹下村",
        "label": "竹下村"
      },
      {
        "value": "桥头村",
        "label": "桥头村"
      },
      {
        "value": "岭头村",
        "label": "岭头村"
      },
      {
        "value": "赤岸村",
        "label": "赤岸村"
      },
      {
        "value": "古岭下村",
        "label": "古岭下村"
      },
      {
        "value": "后岐村",
        "label": "后岐村"
      },
      {
        "value": "青岐村",
        "label": "青岐村"
      }
    ]
  },
  {
    "value": "松山街道",
    "label": "松山街道",
    "children": [

      {
        "value": "经开区",
        "label": "经开区"
      },
      {
        "value": "社区筹备处",
        "label": "社区筹备处"
      },
      {
        "value": "塔下村",
        "label": "塔下村"
      },
      {
        "value": "松农村",
        "label": "松农村"
      },
      {
        "value": "松渔村",
        "label": "松渔村"
      },
      {
        "value": "北岐村",
        "label": "北岐村"
      },
      {
        "value": "沙头村",
        "label": "沙头村"
      },
      {
        "value": "小沙村",
        "label": "小沙村"
      },
      {
        "value": "大沙村",
        "label": "大沙村"
      },
      {
        "value": "长沙村",
        "label": "长沙村"
      },
      {
        "value": "大墓里村",
        "label": "大墓里村"
      },
      {
        "value": "沙塘街村",
        "label": "沙塘街村"
      },
      {
        "value": "沙塘里村",
        "label": "沙塘里村"
      },
      {
        "value": "古县村",
        "label": "古县村"
      }
    ]
  },
  {
    "value": "长春镇",
    "label": "长春镇",
    "children": [

      {
        "value": "长春村",
        "label": "长春村"
      },
      {
        "value": "洪江村",
        "label": "洪江村"
      },
      {
        "value": "渔洋里村",
        "label": "渔洋里村"
      },
      {
        "value": "渔洋垾村",
        "label": "渔洋垾村"
      },
      {
        "value": "秋竹岗村",
        "label": "秋竹岗村"
      },
      {
        "value": "长门村",
        "label": "长门村"
      },
      {
        "value": "武曲村",
        "label": "武曲村"
      },
      {
        "value": "祖厝村",
        "label": "祖厝村"
      },
      {
        "value": "传胪村",
        "label": "传胪村"
      },
      {
        "value": "武岐村",
        "label": "武岐村"
      },
      {
        "value": "文岐村",
        "label": "文岐村"
      },
      {
        "value": "埕坞村",
        "label": "埕坞村"
      },
      {
        "value": "长溪村",
        "label": "长溪村"
      },
      {
        "value": "法华村",
        "label": "法华村"
      },
      {
        "value": "赤沙村",
        "label": "赤沙村"
      },
      {
        "value": "里城村",
        "label": "里城村"
      },
      {
        "value": "外城村",
        "label": "外城村"
      },
      {
        "value": "加竹村",
        "label": "加竹村"
      },
      {
        "value": "吕峡村",
        "label": "吕峡村"
      },
      {
        "value": "小京村",
        "label": "小京村"
      },
      {
        "value": "大京村",
        "label": "大京村"
      },
      {
        "value": "斗米村",
        "label": "斗米村"
      },
      {
        "value": "蜘蛛网村",
        "label": "蜘蛛网村"
      },
      {
        "value": "积石村",
        "label": "积石村"
      },
      {
        "value": "亭下溪村",
        "label": "亭下溪村"
      },
      {
        "value": "下洋城村",
        "label": "下洋城村"
      },
      {
        "value": "渔家地村",
        "label": "渔家地村"
      }
    ]
  },
  {
    "value": "牙城镇",
    "label": "牙城镇",
    "children": [

      {
        "value": "牙城",
        "label": "牙城"
      },
      {
        "value": "前街",
        "label": "前街"
      },
      {
        "value": "西门",
        "label": "西门"
      },
      {
        "value": "洪山",
        "label": "洪山"
      },
      {
        "value": "雉溪",
        "label": "雉溪"
      },
      {
        "value": "凤门",
        "label": "凤门"
      },
      {
        "value": "渡头",
        "label": "渡头"
      },
      {
        "value": "杨家溪",
        "label": "杨家溪"
      },
      {
        "value": "茶坑",
        "label": "茶坑"
      },
      {
        "value": "凤阳",
        "label": "凤阳"
      },
      {
        "value": "凤江",
        "label": "凤江"
      },
      {
        "value": "斗门",
        "label": "斗门"
      },
      {
        "value": "梅花",
        "label": "梅花"
      },
      {
        "value": "一层",
        "label": "一层"
      },
      {
        "value": "文洋",
        "label": "文洋"
      },
      {
        "value": "后洋",
        "label": "后洋"
      },
      {
        "value": "敖岭",
        "label": "敖岭"
      },
      {
        "value": "后山",
        "label": "后山"
      },
      {
        "value": "箩伍",
        "label": "箩伍"
      },
      {
        "value": "左岭",
        "label": "左岭"
      },
      {
        "value": "前楼",
        "label": "前楼"
      },
      {
        "value": "田家心",
        "label": "田家心"
      },
      {
        "value": "东街头",
        "label": "东街头"
      },
      {
        "value": "龙亭",
        "label": "龙亭"
      },
      {
        "value": "凤楼",
        "label": "凤楼"
      }
    ]
  },
  {
    "value": "溪南镇",
    "label": "溪南镇",
    "children": [

      {
        "value": "溪南村",
        "label": "溪南村"
      },
      {
        "value": "溪南社区",
        "label": "溪南社区"
      },
      {
        "value": "半月里村",
        "label": "半月里村"
      },
      {
        "value": "左湾",
        "label": "左湾"
      },
      {
        "value": "甘棠",
        "label": "甘棠"
      },
      {
        "value": "下砚",
        "label": "下砚"
      },
      {
        "value": "七星",
        "label": "七星"
      },
      {
        "value": "东安",
        "label": "东安"
      },
      {
        "value": "西安",
        "label": "西安"
      },
      {
        "value": "关门",
        "label": "关门"
      },
      {
        "value": "溪尾",
        "label": "溪尾"
      },
      {
        "value": "后慕",
        "label": "后慕"
      },
      {
        "value": "台江",
        "label": "台江"
      },
      {
        "value": "仙东",
        "label": "仙东"
      },
      {
        "value": "白露坑",
        "label": "白露坑"
      },
      {
        "value": "芹头",
        "label": "芹头"
      },
      {
        "value": "青山",
        "label": "青山"
      },
      {
        "value": "岱岐头",
        "label": "岱岐头"
      },
      {
        "value": "傅竹",
        "label": "傅竹"
      },
      {
        "value": "霞塘",
        "label": "霞塘"
      },
      {
        "value": "长腰",
        "label": "长腰"
      },
      {
        "value": "猴屿",
        "label": "猴屿"
      },
      {
        "value": "南门山",
        "label": "南门山"
      },
      {
        "value": "南岸",
        "label": "南岸"
      },
      {
        "value": "长兴",
        "label": "长兴"
      },
      {
        "value": "南坂",
        "label": "南坂"
      }
    ]
  },
  {
    "value": "沙江镇",
    "label": "沙江镇",
    "children": [

      {
        "value": "沙江村",
        "label": "沙江村"
      },
      {
        "value": "坝头村",
        "label": "坝头村"
      },
      {
        "value": "竹江村",
        "label": "竹江村"
      },
      {
        "value": "涵江村",
        "label": "涵江村"
      },
      {
        "value": "南屏村",
        "label": "南屏村"
      },
      {
        "value": "梅洋村",
        "label": "梅洋村"
      },
      {
        "value": "小马村",
        "label": "小马村"
      },
      {
        "value": "水潮村",
        "label": "水潮村"
      },
      {
        "value": "白鹭村",
        "label": "白鹭村"
      },
      {
        "value": "厚首村",
        "label": "厚首村"
      },
      {
        "value": "围江村",
        "label": "围江村"
      },
      {
        "value": "大坪村",
        "label": "大坪村"
      },
      {
        "value": "坝头村",
        "label": "坝头村"
      },
      {
        "value": "八堡村",
        "label": "八堡村"
      },
      {
        "value": "龙湾村",
        "label": "龙湾村"
      },
      {
        "value": "坡头村",
        "label": "坡头村"
      },
      {
        "value": "炉坑村",
        "label": "炉坑村"
      }
    ]
  },
  {
    "value": "下浒镇",
    "label": "下浒镇",
    "children": [

      {
        "value": "下浒村",
        "label": "下浒村"
      },
      {
        "value": "下浒社区",
        "label": "下浒社区"
      },
      {
        "value": "外浒",
        "label": "外浒"
      },
      {
        "value": "前洋",
        "label": "前洋"
      },
      {
        "value": "西岐",
        "label": "西岐"
      },
      {
        "value": "柏溪",
        "label": "柏溪"
      },
      {
        "value": "赤壁",
        "label": "赤壁"
      },
      {
        "value": "三洲",
        "label": "三洲"
      },
      {
        "value": "磺砂",
        "label": "磺砂"
      },
      {
        "value": "上澳",
        "label": "上澳"
      },
      {
        "value": "文星明",
        "label": "文星明"
      },
      {
        "value": "王家洞",
        "label": "王家洞"
      },
      {
        "value": "石湖",
        "label": "石湖"
      },
      {
        "value": "留金",
        "label": "留金"
      },
      {
        "value": "金蟹",
        "label": "金蟹"
      },
      {
        "value": "延亭",
        "label": "延亭"
      },
      {
        "value": "四斗",
        "label": "四斗"
      },
      {
        "value": "山斗",
        "label": "山斗"
      },
      {
        "value": "大安",
        "label": "大安"
      },
      {
        "value": "居安",
        "label": "居安"
      },
      {
        "value": "浒水",
        "label": "浒水"
      },
      {
        "value": "柘洋",
        "label": "柘洋"
      }
    ]
  },
  {
    "value": "三沙镇",
    "label": "三沙镇",
    "children": [

      {
        "value": "古镇",
        "label": "古镇"
      },
      {
        "value": "三澳",
        "label": "三澳"
      },
      {
        "value": "西澳村",
        "label": "西澳村"
      },
      {
        "value": "东澳村",
        "label": "东澳村"
      },
      {
        "value": "五澳村",
        "label": "五澳村"
      },
      {
        "value": "中心村",
        "label": "中心村"
      },
      {
        "value": "西澳社区",
        "label": "西澳社区"
      },
      {
        "value": "东澳社区",
        "label": "东澳社区"
      },
      {
        "value": "五澳社区",
        "label": "五澳社区"
      },
      {
        "value": "中心社区",
        "label": "中心社区"
      },
      {
        "value": "陇头",
        "label": "陇头"
      },
      {
        "value": "东山",
        "label": "东山"
      },
      {
        "value": "浮山",
        "label": "浮山"
      },
      {
        "value": "单斗",
        "label": "单斗"
      },
      {
        "value": "三坪",
        "label": "三坪"
      },
      {
        "value": "石头鼻",
        "label": "石头鼻"
      },
      {
        "value": "小皓",
        "label": "小皓"
      },
      {
        "value": "蔡洋",
        "label": "蔡洋"
      },
      {
        "value": "古桶",
        "label": "古桶"
      },
      {
        "value": "虞公亭",
        "label": "虞公亭"
      },
      {
        "value": "东壁",
        "label": "东壁"
      },
      {
        "value": "大路顶",
        "label": "大路顶"
      },
      {
        "value": "八斗",
        "label": "八斗"
      },
      {
        "value": "二坑",
        "label": "二坑"
      },
      {
        "value": "金鸡",
        "label": "金鸡"
      },
      {
        "value": "花竹",
        "label": "花竹"
      },
      {
        "value": "青官司",
        "label": "青官司"
      },
      {
        "value": "青官兰",
        "label": "青官兰"
      },
      {
        "value": "三农",
        "label": "三农"
      },
      {
        "value": "金洋",
        "label": "金洋"
      },
      {
        "value": "烽火",
        "label": "烽火"
      }
    ]
  },
  {
    "value": "盐田乡",
    "label": "盐田乡",
    "children": [

      {
        "value": "盐田",
        "label": "盐田"
      },
      {
        "value": "村里",
        "label": "村里"
      },
      {
        "value": "官岭尾",
        "label": "官岭尾"
      },
      {
        "value": "中贝",
        "label": "中贝"
      },
      {
        "value": "北斗",
        "label": "北斗"
      },
      {
        "value": "洋边",
        "label": "洋边"
      },
      {
        "value": "北洋",
        "label": "北洋"
      },
      {
        "value": "杨梅岭",
        "label": "杨梅岭"
      },
      {
        "value": "二铺",
        "label": "二铺"
      },
      {
        "value": "西胜",
        "label": "西胜"
      },
      {
        "value": "里马",
        "label": "里马"
      },
      {
        "value": "上村",
        "label": "上村"
      },
      {
        "value": "王高店",
        "label": "王高店"
      },
      {
        "value": "龙凤店",
        "label": "龙凤店"
      },
      {
        "value": "瓦窖头",
        "label": "瓦窖头"
      },
      {
        "value": "南塘澳",
        "label": "南塘澳"
      },
      {
        "value": "南塘",
        "label": "南塘"
      },
      {
        "value": "姚澳",
        "label": "姚澳"
      },
      {
        "value": "浒屿澳",
        "label": "浒屿澳"
      },
      {
        "value": "水升",
        "label": "水升"
      },
      {
        "value": "钓岐",
        "label": "钓岐"
      },
      {
        "value": "浒屿",
        "label": "浒屿"
      }
    ]
  },
  {
    "value": "崇儒乡",
    "label": "崇儒乡",
    "children": [

      {
        "value": "崇儒",
        "label": "崇儒"
      },
      {
        "value": "溪边",
        "label": "溪边"
      },
      {
        "value": "路口",
        "label": "路口"
      },
      {
        "value": "半路张",
        "label": "半路张"
      },
      {
        "value": "洋尾兰",
        "label": "洋尾兰"
      },
      {
        "value": "溪坪",
        "label": "溪坪"
      },
      {
        "value": "新村",
        "label": "新村"
      },
      {
        "value": "霞坪",
        "label": "霞坪"
      },
      {
        "value": "坪园",
        "label": "坪园"
      },
      {
        "value": "丘山",
        "label": "丘山"
      },
      {
        "value": "上水",
        "label": "上水"
      },
      {
        "value": "郑洋",
        "label": "郑洋"
      },
      {
        "value": "保安",
        "label": "保安"
      },
      {
        "value": "石亭",
        "label": "石亭"
      },
      {
        "value": "苋下",
        "label": "苋下"
      },
      {
        "value": "亭头",
        "label": "亭头"
      },
      {
        "value": "岚下",
        "label": "岚下"
      },
      {
        "value": "汴洋",
        "label": "汴洋"
      },
      {
        "value": "濂溪",
        "label": "濂溪"
      },
      {
        "value": "东杞洋",
        "label": "东杞洋"
      },
      {
        "value": "左岭",
        "label": "左岭"
      },
      {
        "value": "樟桥",
        "label": "樟桥"
      },
      {
        "value": "溪西",
        "label": "溪西"
      },
      {
        "value": "东坡",
        "label": "东坡"
      },
      {
        "value": "岙里",
        "label": "岙里"
      },
      {
        "value": "长坑",
        "label": "长坑"
      },
      {
        "value": "洋沙溪",
        "label": "洋沙溪"
      }
    ]
  },
  {
    "value": "柏洋乡",
    "label": "柏洋乡",
    "children": [

      {
        "value": "柏洋",
        "label": "柏洋"
      },
      {
        "value": "长岩",
        "label": "长岩"
      },
      {
        "value": "林洋",
        "label": "林洋"
      },
      {
        "value": "车下",
        "label": "车下"
      },
      {
        "value": "陈墩",
        "label": "陈墩"
      },
      {
        "value": "谢墩",
        "label": "谢墩"
      },
      {
        "value": "董墩",
        "label": "董墩"
      },
      {
        "value": "洋中",
        "label": "洋中"
      },
      {
        "value": "柘头",
        "label": "柘头"
      },
      {
        "value": "神洋",
        "label": "神洋"
      },
      {
        "value": "岱家山",
        "label": "岱家山"
      },
      {
        "value": "黄土坵",
        "label": "黄土坵"
      },
      {
        "value": "塔后",
        "label": "塔后"
      },
      {
        "value": "西坑",
        "label": "西坑"
      },
      {
        "value": "大岭",
        "label": "大岭"
      },
      {
        "value": "阮洋",
        "label": "阮洋"
      },
      {
        "value": "坑口",
        "label": "坑口"
      },
      {
        "value": "周厝坑",
        "label": "周厝坑"
      },
      {
        "value": "吴洋",
        "label": "吴洋"
      },
      {
        "value": "横江",
        "label": "横江"
      },
      {
        "value": "坂头",
        "label": "坂头"
      },
      {
        "value": "前宅",
        "label": "前宅"
      },
      {
        "value": "西宅",
        "label": "西宅"
      },
      {
        "value": "后垅",
        "label": "后垅"
      },
      {
        "value": "洋里",
        "label": "洋里"
      },
      {
        "value": "郑家山",
        "label": "郑家山"
      },
      {
        "value": "南山后",
        "label": "南山后"
      },
      {
        "value": "凤洋",
        "label": "凤洋"
      }
    ]
  },
  {
    "value": "北壁乡",
    "label": "北壁乡",
    "children": [

      {
        "value": "北壁",
        "label": "北壁"
      },
      {
        "value": "铁炉",
        "label": "铁炉"
      },
      {
        "value": "东冲",
        "label": "东冲"
      },
      {
        "value": "四门桥",
        "label": "四门桥"
      },
      {
        "value": "盘前",
        "label": "盘前"
      },
      {
        "value": "武岩",
        "label": "武岩"
      },
      {
        "value": "会洋",
        "label": "会洋"
      },
      {
        "value": "上岐",
        "label": "上岐"
      },
      {
        "value": "下岐",
        "label": "下岐"
      },
      {
        "value": "池澳",
        "label": "池澳"
      },
      {
        "value": "河山",
        "label": "河山"
      }
    ]
  },
  {
    "value": "海岛乡",
    "label": "海岛乡",
    "children": [


      {
        "value": "宫东",
        "label": "宫东"
      },
      {
        "value": "宫西",
        "label": "宫西"
      },
      {
        "value": "烟台",
        "label": "烟台"
      },
      {
        "value": "北礵",
        "label": "北礵"
      },
      {
        "value": "里澳",
        "label": "里澳"
      },
      {
        "value": "文澳",
        "label": "文澳"
      }
    ]
  },
  {
    "value": "水门乡",
    "label": "水门乡",
    "children": [

      {
        "value": "芦阳村",
        "label": "芦阳村"
      },
      {
        "value": "大坝村",
        "label": "大坝村"
      },
      {
        "value": "上洋村",
        "label": "上洋村"
      },
      {
        "value": "小竹湾",
        "label": "小竹湾"
      },
      {
        "value": "长湖村",
        "label": "长湖村"
      },
      {
        "value": "八斗丘村",
        "label": "八斗丘村"
      },
      {
        "value": "承天村",
        "label": "承天村"
      },
      {
        "value": "百笕村",
        "label": "百笕村"
      },
      {
        "value": "武坪村",
        "label": "武坪村"
      },
      {
        "value": "湖里村",
        "label": "湖里村"
      },
      {
        "value": "里洋村",
        "label": "里洋村"
      },
      {
        "value": "玉山村",
        "label": "玉山村"
      },
      {
        "value": "大洋村",
        "label": "大洋村"
      },
      {
        "value": "七斗岔村",
        "label": "七斗岔村"
      },
      {
        "value": "茶岗村",
        "label": "茶岗村"
      },
      {
        "value": "高盘村",
        "label": "高盘村"
      },
      {
        "value": "青岙村",
        "label": "青岙村"
      },
      {
        "value": "墩后村",
        "label": "墩后村"
      },
      {
        "value": "水门村",
        "label": "水门村"
      },
      {
        "value": "桥头村",
        "label": "桥头村"
      },
      {
        "value": "水井头",
        "label": "水井头"
      },
      {
        "value": "半岭村",
        "label": "半岭村"
      },
      {
        "value": "大坪村",
        "label": "大坪村"
      }
    ]
  }
];
export default area;