<template>
<div id="indexlayout">
  <layout-index-left />
  <div id="indexlayout-right" :class="{ 'fiexd-header': siteFiexdHeader }">
    <layout-index-right-top />
    <div class="indexlayout-right-main">
      <div class="main-conent main-conent-minheight">
        <el-card shadow="never" class="border-none">
          <div slot="header">
            <el-row>
              <el-col :span="8">
                <el-button type="primary" @click="showCreateDialog">新增</el-button>
              </el-col>
            </el-row>
          </div>
          <el-table :data="tableData" stripe>
            <el-table-column fixed type="index" label="序号" width="150">
            </el-table-column>
            <el-table-column prop="name" label="医生姓名" min-width="120">
            </el-table-column>
            <el-table-column prop="phone" label="手机" min-width="120">
            </el-table-column>
            <el-table-column prop="last_login" label="最近一次登录" min-width="120">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
       
                <el-button @click="deleteUser(scope.row)" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>

          <!--新建-->
  <el-dialog title="新建" :visible.sync="createDialogFlag" width="450px">
    <el-form :model="createform"  style="padding-left:50px">
      <el-form-item label="姓名" :label-width="120">
        <el-input v-model="createform.name" autocomplete="off" style="width:250px"></el-input>
      </el-form-item>
      <el-form-item label="手机" :label-width="120" >
        <el-input v-model="createform.phone" autocomplete="off" style="width:250px"></el-input>
      </el-form-item>
      <el-form-item label="密码" :label-width="120">
        <el-input v-model="createform.password" autocomplete="off" style="width:250px"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="createDialogFlag = false">取 消</el-button>
      <el-button type="primary" @click="createUser">确 定</el-button>
    </span>
  </el-dialog>

      </div>
      <layout-index-right-footer />
    </div>
  </div>

</div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import LayoutIndexLeft from '@/layout/Index/LayoutIndexLeft';
import LayoutIndexRightTop from '@/layout/Index/LayoutIndexRightTop';
import LayoutIndexRightFooter from '@/layout/Index/LayoutIndexRightFooter';

import area2 from '@/utlis/area2.js';

import request from '@/service/lib/request';


export default {
  components: {
    LayoutIndexLeft,
    LayoutIndexRightTop,
    LayoutIndexRightFooter
  },
  data() {
    return {
      createDialogFlag:false,
      createform:{
        name:'',
        password:'',
        phone:''
      },
      doctor_name: '',

      smstitle: '群发短信',
      smsFlag: 0,
      smsTimeout: 10,

      activeName: 'first',
      radio1: '全部',
      input1: '',
      editDialogFlag: false,
      smsDialogFlag: false,
      options: [{
        label: '全部',
        value: ''
      }, {
        label: '老年人免费体检',
        value: 1
      }, {
        label: '重点人群免费体检',
        value: 2
      }, {
        label: '自定义体检项目',
        value: 3
      }],
      options2: area2,
      value: '',
      last_count: 0,
      special_user: '',

      doctor_arr: [

      ],
      disease_arr: [{
          label: '全部',
          value: 0
        },
        {
          label: '高血压',
          value: 1
        },
        {
          label: '糖尿病',
          value: 2
        },
        {
          label: '重性精神病',
          value: 3
        },

        {
          label: '脑卒中',
          value: 4
        }

      ],
      form: {
        name: '',
        locations: ['福建省', '宁德市', '霞浦县'],
        sublocations: ['']
      },
      medical_arr: [{
          label: '全部',
          value: ''
        },
        {
          label: '新冠疫苗（腺病毒）-1针法',
          value: 1
        },

        {
          label: '新冠疫苗（Vero细胞）-2针法',
          value: 2
        }
      ],
      pager: {
        total: 0,
        page_size: 100,
        page_count: 0,
        current: 0
      },


      tableData: [],
      multipleSelection: [],
      sms_options: [],
      manData: [],
      smsData: []
    };
  },
  computed: {
    ...mapGetters(['siteFiexdHeader'])
  },
  created() {
    // this.initOption();

  },
  methods: {

    init() {
      request({
          url: 'http://scfwzx.3dy.me/api/doctor/',
          method: 'get'
        })
        .then((res) => {
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    setName() {
      request({
          url: 'http://scfwzx.3dy.me/api/doctor/2/',
          method: 'put',
          data: {
            note: this.doctor_name
          }
        })
        .then((res) => {
          console.log(res);

          this.$message.success('修改成功！');
          this.editDialogFlag = false;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    showCreateDialog(){
      this.createDialogFlag=true;
    },
    createUser(){
            request({
        url: '/admin/create',
        method: 'post',
        data: this.createform
      }).then(res => {
        console.log(res);
        if (res.code === 'success') {
          this.$message.success('新建用户成功！');
          this.createDialogFlag = false;
         
          this.tableData=[res.data,...this.tableData];
        } else {
          this.$message.error('新建用户失败！');
        }
      }).catch(err => {
         this.$message.error(err.message);
        this.createDialogFlag = false;
      });
    },
    deleteUser(row){
       this.$confirm('删除 '+row.name+' , 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row);
        request({
          url:'/admin/delete/',
          method:'post',
          data:{
            id:row.id
          }
        }).then(res=>{
          this.$message({
          type: 'success',
          message: '删除成功!'
        });
        console.log(res);
        this.tableData.splice(this.tableData.indexOf(row));
        }).catch(err=>{
          console.log(err.message);
        });
      }).catch(() => {
        console.log(row);
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        
      });
    }

  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/variables.scss';

.red_day {
  color: red;
  font-weight: 700;
}

.scrolldiv {
  height: 4em;
  width: 100%;
  overflow: auto;
}

.title {
  margin-right: 10px;
  width: 110px;
  font-size: 14px;
}

#indexlayout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

#indexlayout-right {
  position: relative;
  flex: 1;
  overflow: auto;
  background-color: $mainBgColor;

  &.fiexd-header {
    display: flex;
    flex-direction: column;

    .indexlayout-right-main {
      flex: 1;
      overflow: auto;
    }
  }
}

.aradio {
  width: 500px;
  line-height: 3.2;
}
</style>
